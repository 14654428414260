@font-face {
    font-family: 'Oswald';
    src: url('./fonts/Oswald/oswald-regular-webfont.woff2') format('woff2'),
        url('./fonts/Oswald/oswald-regular-webfont.woff') format('woff');
    font-weight: normal;
}

@font-face {
    font-family: 'Oswald';
    src: url('./fonts/Oswald/oswald-bold-webfont.woff2') format('woff2'),
        url('./fonts/Oswald/oswald-bold-webfont.woff') format('woff');
    font-weight: bold;
}

@font-face {
    font-family: 'Oswald';
    src: url('./fonts/Oswald/oswald-light-webfont.woff2') format('woff2'),
        url('./fonts/Oswald/oswald-light-webfont.woff') format('woff');
    font-weight: 200;
}

@font-face {
    font-family: 'Cardo';
    src: url('./fonts/Cardo/cardo-regular-webfont.woff2') format('woff2'),
        url('./fonts/Cardo/cardo-regular-webfont.woff') format('woff');
    font-weight: normal;
}
