.App {
    background-color: var(--background);
    border: 1px solid green;
    max-width: 102.8em;
    width: 100%;
    margin: 0 auto;
    font-family: 'Cardo';
    font-size: 1.6em;

    h1 {
        text-align: center;
    }
    section {
        img {
            width: 100%;
        }
    }
}
